<template>
	<div>
		<HeaderPage/><br/><br/>
		<section class="faq">
        <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Bollard</h2>
          <h4>NewsLetter By Vilsons Group</h4>
        </div>

        <ul class="faq-list accordion" data-aos="fade-up">
          <li>
            <a href="assets/newsletter/2022/bollard-01012022.pdf" target="_blank">Bollard Voyage 02 Released on 01st Jan 2022</a>
          </li>
          <li>
            <a href="assets/newsletter/2021/bollard-25122021.pdf" target="_blank">Bollard Voyage 01 Released on 25th Dec 2021</a>
          </li>
        </ul>
      </div>
    </section>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'Newsletterpage',
  components: {
    HeaderPage,
  },
}
</script>